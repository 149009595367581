.root {
	composes: g-grid-container from global;
}

.inner {
	composes: g-grid from global;
	row-gap: var(--hdsplus-spacing-07);
	align-items: center;
}

.text {
	grid-column: 1 / -1;
	display: grid;
	gap: var(--hdsplus-spacing-03);

	@media (--medium-up) {
		grid-column: 1 / 6;
		gap: var(--hdsplus-spacing-05);
	}
}

.mobileCtas {
	grid-column: 1 / -1;
	display: grid;
	gap: var(--hdsplus-spacing-04);

	@media (--medium-up) {
		display: none;
	}
}

.ctas {
	display: none;
	gap: var(--hdsplus-spacing-04);

	@media (--medium-up) {
		grid-column: 7 / -1;
		display: flex;
		justify-content: flex-end;
	}
}
