.root {
	composes: g-grid-container from global;
	composes: g-grid from global;
}

.content {
	--grid-column-rule: span 12;
	
	grid-column: var(--grid-column-rule);

	&.inset {
		--grid-column-rule: span 4;

		@media (--medium-up) {
			--grid-column-rule: 2 / span 10;
		}

		@media (--large) {
			--grid-column-rule: 3 / span 8;
		}
	}
}