.container {
	composes: g-grid-container from global;
}

.divider {
	position: relative;
	z-index: 1;
	background: var(--materia-accent-border-color, rgba(178, 182, 189, 0.2));
	height: var(--materia-accent-border-width, 1px);
	margin-top: calc(var(--materia-accent-border-width, 1px) * -1);
	margin-bottom: 0;
}
