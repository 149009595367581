.root {
	composes: g-grid-container from global;
}

.pricingTiers {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	flex-direction: column;
	box-shadow: var(--token-surface-mid-box-shadow);
	padding: var(--hdsplus-spacing-10);
	gap: var(--hdsplus-spacing-10);
	border-radius: 8px;
	text-align: center;

	@media (--large) {
		padding: var(--hdsplus-spacing-12);
	}
}

.header {
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-08);
	align-items: center;
}

.tiers {
	display: grid;
	width: 100%;
	column-gap: var(--hdsplus-spacing-07);

	@media (--medium-up) {
		grid-template-columns: repeat(var(--cols), minmax(0, 1fr));

		& .tierHeader {
			grid-row: 1;
		}

		& .details {
			grid-row: 2;
		}

		& .description {
			grid-row: 3;
		}

		& .footer {
			grid-row: 4;
		}
	}

	@media (--large) {
		column-gap: var(--hdsplus-spacing-10);
	}
}

.tierHeader {
	padding-bottom: var(--hdsplus-spacing-08);

	&:not(:first-child) {
		padding-top: var(--hdsplus-spacing-08);
		border-top: 1px solid var(--token-color-border-primary);

		@media (--medium-up) {
			border-top: 0;
			padding-top: 0;
		}
	}
}

.details {
	display: grid;
	padding-bottom: var(--hdsplus-spacing-05);
	row-gap: var(--hdsplus-spacing-02);
}

.description {
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-02);

	@media (--medium-up) {
		max-width: 360px;
	}
}

.footer {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	padding-top: var(--hdsplus-spacing-08);
	gap: var(--hdsplus-spacing-03);

	@media (--medium-up) {
		padding-bottom: 0;
	}

	&:not(:last-child) {
		padding-bottom: var(--hdsplus-spacing-08);
		@media (--medium-up) {
			padding-bottom: 0;
		}
	}
}

.inlineLink {
	word-break: break-word;
	text-decoration: underline;
	color: inherit;
}

.ctas {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-07);
}
