/* TODO: refactor this so it's easier to understand */
.root {
	--backgroundColor: var(--token-color-surface-primary);
	--tileDimension: 72px;
	--gap: 32px;
	--scrimWidth: 0;

	position: relative;
	composes: g-grid-container from global;
	margin: 0 auto;
	display: grid;
	row-gap: var(--hdsplus-spacing-08);
	justify-content: center;
	background-color: var(--backgroundColor);
	overflow: hidden;

	&::before {
		position: absolute;
		content: '';
		inset: 0;
		height: 100%;
		width: var(--scrimWidth);
		background: linear-gradient(to right, var(--backgroundColor), transparent);
	}

	&::after {
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		height: 100%;
		width: var(--scrimWidth);
		background: linear-gradient(to left, var(--backgroundColor), transparent);
	}

	@media (--medium-up) {
		--scrimWidth: 150px;

		grid-template-columns: repeat(12, var(--tileDimension));
		grid-template-rows: repeat(6, minmax(var(--tileDimension), auto));
		gap: var(--gap);
	}

	@media (--large) {
		--scrimWidth: 250px;
	}
}

.mobileImage {
	grid-column: 1 / -1;

	& img {
		max-width: 100%;
		height: auto;
		vertical-align: middle;
	}

	@media (--medium-up) {
		display: none;
	}
}

.textWrapper {
	grid-column: 1 / -1;
	
	@media (--medium-up) {
		padding: 0 var(--hdsplus-spacing-09);
		grid-column: 4 / 10;
		grid-row: 2 / -1;
	}
}

.tile {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	width: 100%;
	height: 100%;
	padding: var(--hdsplus-spacing-04);
	
	@media (min-width: 375px) {
		padding: var(--hdsplus-spacing-05);
	}

	& img {
		max-width: 100%;
		height: auto;
	}
	
	&.small {
		grid-column: auto / span 1;
		grid-row: auto / span 1;
	}
	
	&.medium {
		grid-column: auto / span 2;
		grid-row: auto / span 1;
	}
	
	&.large {
		grid-column: auto / span 2;
		grid-row: auto / span 2;
	}

	&.hasImage {
		border: 1px solid var(--token-color-border-faint);
		background: linear-gradient(149deg, rgba(239, 239, 241, 0.27) 10.79%, rgba(239, 239, 241, 0.00) 76.87%);
	}

	&.isMobile {
		grid-column: auto / span 1;
		@media (--medium-up) {
			display: none;
		}
	}

	&.isDesktop {
		display: none;
		
		@media (--medium-up) {
			display: flex;
		}
	}
}

.eyebrow {
	composes: hdsplus-typography-label from global;
	color: var(--token-color-foreground-faint);
	text-transform: uppercase;
	font-weight: 600;
	margin-bottom: var(--hdsplus-spacing-03);

	@media (--medium-up) {
		text-align: center;
		margin-bottom: var(--hdsplus-spacing-04);
	}
}

.headline {
	color: var(--token-color-foreground-strong);
	margin: 0 0 var(--hdsplus-spacing-06);
	font-weight: 700;
	font-family: var(--hdsplus-typography-display-expressive-300-font-family);
	font-size: var(--hdsplus-typography-display-expressive-300-font-size);
	line-height: var(--hdsplus-typography-display-expressive-300-line-height);

	@media (--medium-up) {
		font-family: var(--hdsplus-typography-display-expressive-400-font-family);
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		text-align: center;
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-500-font-family);
		font-size: var(--hdsplus-typography-display-expressive-500-font-size);
		line-height: var(--hdsplus-typography-display-expressive-500-line-height);
	}
}

.description {
	margin: 0 0 var(--hdsplus-spacing-06);
	font-family: var(--token-typography-body-200-font-family);
	font-size: var(--token-typography-body-200-font-size);
	line-height: var(--token-typography-body-200-line-height);
	color: var(--token-color-foreground-faint);

	@media (--medium-up) {
		margin-bottom: var(--hdsplus-spacing-08);
		text-align: center;
		font-family: var(--token-typography-body-300-font-family);
		font-size: var(--token-typography-body-300-font-size);
		line-height: var(--token-typography-body-300-line-height);
	}
}

.ctas {
	display: flex;
	flex-wrap: wrap;
	row-gap: var(--hdsplus-spacing-04);
	column-gap: var(--hdsplus-spacing-07);

	@media (--medium-up) {
		justify-content: center;
	}
}

.icon {
	display: flex;
	height: 100%;
}

.stats {
	display: flex;
	justify-content: space-between;
	gap: var(--hdsplus-spacing-06); 
	list-style-type: none;
	margin: 0;
	padding: 0;
	margin-top: var(--hdsplus-spacing-08);
	flex-wrap: wrap;

	@media (min-width: 340px) {
		flex-wrap: nowrap;
	}

	@media (--medium-up) {
		gap: var(--hdsplus-spacing-07); 
	}

	@media (--large) {
		margin-top: var(--hdsplus-spacing-07);
	}
}

.stat {
	flex: 1 1 100%;

	@media (--medium-up) {
		text-align: center;
	}
}

.statNumber {
	font-weight: 700;
	margin: 0;
	padding: 0;
	color: var(--token-color-palette-neutral-700);
	font-family: var(--hdsplus-typography-display-expressive-100-font-family);
	font-size: var(--hdsplus-typography-display-expressive-100-font-size);
	line-height: var(--hdsplus-typography-display-expressive-100-line-height);

	@media (--medium-up) {
		font-family: var(--hdsplus-typography-display-expressive-200-font-family);
		font-size: var(--hdsplus-typography-display-expressive-200-font-size);
		line-height: var(--hdsplus-typography-display-expressive-200-line-height);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-300-font-family);
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
	}
}

.statText {
	margin: 0;
	padding: 0;
	color: var(--token-color-palette-neutral-500);
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: 13px;
	line-height: 18px;

	@media (--medium-up) {
		font-family: var(--hdsplus-typography-body-200-font-family);
		font-size: var(--hdsplus-typography-body-200-font-size);
		line-height: var(--hdsplus-typography-body-200-line-height);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-body-300-font-family);
		font-size: var(--hdsplus-typography-body-300-font-size);
		line-height: var(--hdsplus-typography-body-300-line-height);
	}
}
