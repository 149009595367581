.root {
	composes: g-grid from global;
	align-items: center;
	row-gap: var(--hdsplus-spacing-10);
}

.content {
	row-gap: var(--hdsplus-spacing-09);
	grid-column: 1 / -1;

	@media (--medium-up) {
		z-index: 1;
		grid-row: 1 / -1;
		grid-column: 1 / 7;

		@nest .reverse & {
			grid-column: 7 / -1;
		}
	}

	/* Non-standard values on --large to align with spacing in the spec */
	@media (--large) {
		grid-column: 1 / 6;

		@nest .reverse & {
			grid-column: 8 / -1;
		}
	}
}

.image {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 7 / -1;
		grid-row: 1 / -1;

		@nest .reverse & {
			grid-column: 1 / 7;
		}
	}

	& img {
		max-width: 100%;
		height: auto;
	}
}
