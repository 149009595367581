.root {
	composes: g-grid-container from global;
	composes: g-grid from global;
}

.layout {
	--background-color-primary-override: var(
		--materia-accent-file-download-background-color-primary
	);

	@media (--large) {
		--small: var(--hdsplus-spacing-10);
		--medium: var(--hdsplus-spacing-12);
		--large: var(--hdsplus-spacing-14);
	}
}

.thumbnail {
	grid-column: span 1;
	align-self: flex-start;
	border-radius: var(--hdsplus-spacing-03);
	overflow: hidden;
	margin-top: var(--hdsplus-spacing-02);

	@media (max-width: 480px) {
		grid-column: span 4;
		max-width: 160px;
		margin-bottom: var(--hdsplus-spacing-04);
	}

	@media (--medium-up) {
		grid-column: span 2;
	}

	& img {
		display: block;
		max-width: 100%;
		height: auto;
	}
}

.img {
	display: block;
	aspect-ratio: 3 / 2;
	background: red;
}

.content {
	grid-column: span 3;
	display: flex;
	align-items: center;

	@media (max-width: 480px) {
		grid-column: span 4;
	}

	@media (--medium-up) {
		grid-column: span 10;
	}
}

.contentInner {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: var(--hdsplus-spacing-04);

	&.hasDetails {
		align-items: flex-start;
	}

	@media (--medium-up) {
		flex-direction: row;
		gap: var(--hdsplus-spacing-08);
	}
}

.copy {
	color: var(--token-color-foreground-strong);
	display: contents;
	gap: var(--hdsplus-spacing-02);
	max-width: 680px;
	margin-right: auto;

	@media (--medium-up) {
		display: grid;
	}
}

.details {
	color: var(--token-color-foreground-primary);
	font-size: 0.625rem;
	order: 3;

	@media (--medium-up) {
		order: unset;
	}
}

.action {
	white-space: nowrap;
}
