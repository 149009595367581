.root {
	display: flex;
	flex-direction: column;
	composes: g-grid-container from global;
	row-gap: var(--hdsplus-spacing-12);
	column-gap: var(--hdsplus-spacing-08);
}

.content {
	composes: g-grid from global;
}

.intro {
	grid-column: 1 / -1;

	@media (--medium) {
		grid-column: 1 / span 8;
	}

	@media (--large) {
		grid-column: 1 / span 6;
	}
}

.editorialBlocks {
	display: flex;
	flex-direction: column;
	row-gap: var(--hdsplus-spacing-14);
}
