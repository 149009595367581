.multiColumnHighlight {
	composes: g-grid-container from global;
	composes: g-grid from global;
}

.columnItem {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: span 6;
		max-width: 488px;
	}

	@media (--large) {
		padding-left: var(--hdsplus-spacing-08);
	}
}

.eyebrow {
	composes: columnItem;
	grid-row: 1;
	margin-bottom: var(--hdsplus-spacing-07);

	& ~ .eyebrow {
		@media (--small) {
			margin-top: var(--hdsplus-spacing-08);
			grid-row: 5;
		}
	}
}

.heading {
	composes: columnItem;
	grid-row: 2;
	margin-bottom: var(--hdsplus-spacing-04);

	& ~ .heading {
		@media (--small) {
			grid-row: 6;
		}
	}
}

.description {
	composes: columnItem;
	grid-row: 3;
	margin-top: 0;
	margin-bottom: var(--hdsplus-spacing-08);

	& ~ .description {
		@media (--small) {
			grid-row: 7;
		}
	}
}

.standaloneLink {
	composes: columnItem;
	grid-row: 4;

	& ~ .standaloneLink {
		@media (--small) {
			grid-row: 8;
		}
	}
}

.icon {
	height: 100%;
	display: flex;
}
