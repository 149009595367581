.root {
	composes: g-grid from global;
	composes: g-grid-container from global;
	align-items: center;
	margin: auto;
}

.imageWrapper {
	grid-column: 1/ -1;
}

.image {
	max-width: 100%;
	height: auto;
	margin: auto;
}

@media (--small) {
	.hideOnMobile {
		display: none;
	}
	.mobileImage {
		display: block;
	}
}

@media (--medium-up) {
	.hideOnMobile {
		display: block;
	}
	.mobileImage {
		display: none;
	}
}
